import CartPopup from "./cart_popup";

$(function() {

    let showLoginForm = function(e){
        e.preventDefault();
        $('.woocommerce-form-login').show();
        $('.woocommerce-form-register').hide();
    };

    let showRegisterForm = function(e){
        e.preventDefault();
        $('.woocommerce-form-register').show();
        $('.woocommerce-form-login').hide();
    };

    $('.login-form-trigger').click((e) => showLoginForm(e));
    $('.register-form-trigger').click((e) => showRegisterForm(e));

});
