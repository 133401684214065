$(function() {

    $(document.body).on('click', '.quantity-input-wrap .quantity-minus', function(e) {

        let wrap = $(this).parents('.quantity-input-wrap');
        let input = $(wrap).find('input.qty');

        let min = $(input).attr('min');

        if(min) {
            min = parseFloat(min);
            if(($(input).val() - 1) < min) return;
        }

        $(input).val($(input).val() - 1);
        $(input).trigger('change');

    });


    $(document.body).on('click', '.quantity-input-wrap .quantity-plus', function(e) {

        let wrap = $(this).parents('.quantity-input-wrap');
        let input = $(wrap).find('input.qty');

        let max = $(input).attr('max');

        if(max) {
            max = parseFloat(max);
            if((parseFloat($(input).val()) + 1) > max) return;
        }

        $(input).val(parseFloat($(input).val()) + 1);
        $(input).trigger('change');

    });


});
