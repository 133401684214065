import CartPopup from "./cart_popup";

$(function() {

    $('.menu-trigger').click(function (e) {
        e.preventDefault();
        $(document.body).toggleClass('sm-menu-open');
    });

    $('.sm-menu .menu-item-has-children').click(function (e){

        if($(this).hasClass('open')) return true;

        e.preventDefault();

        $(this).addClass('open');

        $(this).find('.sub-menu').slideToggle();

    });

});
