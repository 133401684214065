import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

import CartPopup from './cart_popup';
import './login';
import './nav';
import './qty_input';



$(function() {

    let cartPopup = new CartPopup();

    $('.search-form-input-container input[type="text"]').focus(function () {
        $(this).parents('.search-form-input-container').addClass('focused');
    });

    $('.categories-bar-sm-trigger').click(function() {

        $('.categories-bar').toggleClass('open');

        $('.categories-bar-list-wrap').slideToggle();

    });

    let updateBodyMargin = function () {
        let navHeight = $('#floating-nav-container').height();
        $(document.body).css('margin-top', navHeight + 'px');
    };

    setTimeout(function (){
        updateBodyMargin();
    }, 1000)

    $(window).resize(function() {
        $('.categories-bar').removeClass('open');
        $('.categories-bar-list-wrap').attr('style', '');
        updateBodyMargin();
    });

    $( "#loader" ).animate({
        opacity: 0
    }, 500, function() {
        $( "#loader" ).hide(0);
    });


    let infoSlider = $('.info-slider').owlCarousel({
        loop: false,
        nav: true,
        dots: false,
        autoHeight: false,
        autoplay: true,
        navText: "",
        items: 1
    });

});
