export default class CartPopup
{
    constructor()
    {
        console.log('cart-popup-constructor');
        $(document.body).on('added_to_cart', (ev, fragmentsJSON, cart_hash, button) => {this.handleAddedToCart(ev, fragmentsJSON, cart_hash, button)});

        $(document.body).on('click', '.close-modal-trigger', this.closeModal);
        $('.cart-popup-overlay').click(this.closeModal);

        $(document.body).on('click', '.cart-popup-minus-button', () => {this.subtractProductQuantity()});
        $(document.body).on('click', '.cart-popup-plus-button', () => {this.addProductQuantity()});

        $(document.body).on('change', '.cart-popup-modal input.qty', () => {this.updateProductQuantity()});
        $(document.body).on('keyup', '.cart-popup-modal input.qty', (e) => {this.handleQuantityInputKeyUp(e)});

        this.modalVisible = false;
        this.updateTimeout = null;
    }

    closeModal()
    {
        $('.cart-popup-overlay').fadeOut(300);
        $('.cart-popup-modal').removeClass('active');
        this.modalVisible = false;
    }

    showModal()
    {
        $('.cart-popup-overlay').fadeIn(300);
        $('.cart-popup-modal').addClass('active');
        this.modalVisible = true;
    }

    handleAddedToCart(ev, fragmentsJSON, cart_hash, button)
    {
        console.log(fragmentsJSON);
        this.showModal();
    }

    subtractProductQuantity()
    {
        let quantityInput = $('.cart-popup-modal input.qty');
        let currentVal = $(quantityInput).val();
        let min = $(quantityInput).attr('min');

        if(!min) {
            $(quantityInput).val(parseInt(currentVal) - 1);
            $(quantityInput).trigger('change');
            return;
        }

        if(currentVal - 1 < min) return;

        $(quantityInput).val(parseInt(currentVal) - 1);
        $(quantityInput).trigger('change');
    }

    addProductQuantity()
    {
        let quantityInput = $('.cart-popup-modal input.qty');
        let currentVal = $(quantityInput).val();
        let max = $(quantityInput).attr('max');

        if(!max) {
            $(quantityInput).val(parseInt(currentVal) + 1);
            $(quantityInput).trigger('change');
            return;
        }

        if(currentVal + 1 > max) return;

        $(quantityInput).val(parseInt(currentVal) + 1);
        $(quantityInput).trigger('change');
    }


    updateProductQuantity()
    {
        if(!this.modalVisible) return;

        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => {this.sendUpdateRequest()}, 1000);
    }


    sendUpdateRequest()
    {
        let quantityInput = $('.cart-popup-modal input.qty');
        let currentVal = $(quantityInput).val();

        $(quantityInput).blur();

        if(!currentVal) currentVal = 0;

        let productId = $('#cart-popup-current-product-id').val();

        $('.cart-popup-modal').addClass('loading');

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: theme_ajax.ajax_url,
            data: {action: 'update_item_from_cart', 'product_id': productId, 'qty': currentVal,},
            success: (data) => {
                $('.cart-popup-modal').removeClass('loading');
                if ( data && data.fragments ) {
                    $( document.body ).trigger( 'added_to_cart', [ data.fragments, data.cart_hash, null ] );
                }
                if(currentVal == 0) this.closeModal();
            },
            error: () => {
                $('.cart-popup-modal').removeClass('loading');
                if(!this.modalVisible) return;
                alert('Wystąpił błąd. Nastąpi przeładowanie strony.');
                window.location.reload();
            }
        });
    }

    handleQuantityInputKeyUp(e)
    {
        if(!isFinite(e.key) && e.key !== 'Backspace') return;
        this.updateProductQuantity();
    }

}

